.dashboard-main {
  background-image: url('/Auth-background.png');
    /* background-color: #EDF5FF; */
    height: 100vh;
  }
  /* .text-style div {
    background-color: rgb(255, 255, 255);
    font-size: 15px;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
  } */
  
  .navbar-custom {
    background-color: #000000;
    background-image: linear-gradient(147deg, #000000 0%, #2c3e50 74%);
  }
  
  .navbar-custom div {
    padding: .5rem 1rem
  }
  
  .navbar-light a {
    color: white !important
  }
    
  .top-navbar {
      position: relative;
  }
  .top-navbar:after {
      content: "You Are Offline!";
      display: block;
      background: #c00;
      height: 29px;
      width: 20%;
      min-width: 230px;
      text-align: center;
      position: absolute;
      color: white;
      bottom: -29px;
      left: 45%;
      margin-left: -95px;
      z-index: 9;
  }
  
  .nav-btn1 {
    margin-top: 0px
  }
  
  .Nav-Button {
    margin-bottom: 5px;
    border-radius: 50px !important; 
    -moz-border-radius: 50px !important; 
    -webkit-border-radius: 50px !important; 
    border: 2px solid #000000 !important;
    background: #000000 !important;
  }
  
  .footer-line {
    height: 5px;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 11;
    background-color: #00D06A;
  }
  
  @media (max-width: 575px){
    #dash-button, #logout-button {
      display: none !important;
    }
  
    .nav-btn-icon {
      display: inline !important;
    }
  
    .nav-btn1-icon {
      display: inline !important;
    }
  }